import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import { DateOptions } from "@utils/dateFormat";
import Breadcrumb from "@components/Breadcrumb";
import { DeepNonNullable } from "ts-essentials";
import { SpecificHelpDataQuery } from "../../../../graphqlTypes";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody, mdxExcerpt } from "@utils/mdxUtils";
import { ArticleJsonLd } from "gatsby-plugin-next-seo";
import { useLocation } from "@reach/router";

export const query = graphql`
  query SpecificHelpData($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStrapiHelpCategory {
      nodes {
        slug
        name
      }
    }
    allStrapiHelpPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          subtitle
          slug
          childStrapiHelpPageArticle {
            childMdx {
              body
              excerpt
            }
          }
          published_at
          updated_at
          category {
            name
            slug
          }
        }
      }
    }
  }
`;

const HelpPage = ({
  data,
}: {
  data: DeepNonNullable<SpecificHelpDataQuery>;
}) => {
  const { node } = data.allStrapiHelpPage.edges[0];

  const categories = data.allStrapiHelpCategory.nodes;

  const {
    updated_at,
    title,
    category,
    childStrapiHelpPageArticle: article,
  } = node;

  const { siteUrl } = data.site.siteMetadata;
  const { pathname } = useLocation();

  return (
    <Layout>
      <Seo title={title} />

      <ArticleJsonLd
        url={`${siteUrl}${pathname}`}
        datePublished={updated_at}
        description={mdxExcerpt(article)}
        headline={title}
      />

      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto mt-4 mb-4">
        <Breadcrumb
          title={title}
          parents={[
            { title: "Help", url: "/help/" },
            { title: category.name, url: "/help/" + category.slug + "/" },
          ]}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <p className="text-4xl text-white font-semibold mt-16 mb-10">
              {title}
            </p>
            <p className="text-sm text-gray-300 font-light">
              Last updated on{" "}
              {new Date(updated_at).toLocaleString("en-US", DateOptions)}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-accuRankerCream full-width-strip">
        <div className="max-w-fit bg-accuRankerCream w-full py-12 mx-5 md:m-auto">
          <div className="max-w-7xl relative mx-auto mt-4 mb-4 grid grid-cols-5 gap-10 px-4 lg:px-0">
            <div className="col-start-1 col-end-6 lg:col-end-5">
              <MDXRenderer>{mdxBody(article)}</MDXRenderer>
            </div>
            <div className="hidden lg:block col-span-1">
              <div className="sticky top-44 flex flex-col gap-y-2 items-start md:w-80 bg-white p-12 rounded-xl border-2">
                <h2 className="mb-4">Categories</h2>
                {categories.map(categoryEntry => {
                  const { name, slug } = categoryEntry;
                  return (
                    <Link to={`/help/${slug}/`}>
                      <p className="m-0">{name}</p>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default HelpPage;
